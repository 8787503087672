import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import useFileUpload from "react-use-file-upload";
import "./uploadFile.css";
import UploadLogoImg from "../../assets/images/upload-logo";
import CloseIcon from "@mui/icons-material/Close";
import HTTPService from "../../services/HTTPService";
import UrlConstants from "../../utils/constants/urlConstants";
import {
  flushLocalstorage,
  getRoleLocal,
  getUserLocal,
} from "../../utils/common/common";
import { useNavigate } from "react-router-dom";
import UploadFile from "../../components/uploadFile/UploadFile";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Snackbar, Alert } from "@mui/material";
import Loader from "../../components/Loader/Loader";
import d2foLogo from "../../assets/images/d2fo-logo.png";

// import uploadLogo from "../../assets/images/upload-lo"

const UploadFilePage = () => {
  // let userRole= getRoleLocal()
  const [userRole, setUserRole] = useState(getRoleLocal());
  const [loader, setLoader] = useState(false);
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [userName, setUserName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState();
  const [uploadedProductCatalog, setUploadedProductCatalog] = useState();
  const [clearFiles, setClearFiles] = useState(0)

  let vertical = "top";
  let horizontal = "center";

  let navigate = useNavigate();

  const handleSnackbarClose = () => {
    setSnackbarState(false);
  };

  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();

  const inputRef = useRef();

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    if (percent < 100) {
      console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);
    }
  };

  const handleUploadeFiles = (e) => {
    var file = document.querySelector("#uploade-file-input").files[0];

    console.log("file on change", file);
    setUploadedFiles(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = createFormData();
    let newFormData = new FormData();

    newFormData.append("excel", uploadedFiles);
    // newFormData.append("org_id", JSON.parse(localStorage.getItem("org_id")));
    // newFormData.append("user_id", JSON.parse(localStorage.getItem("userId")));
    // newFormData.append("fpc_id", JSON.parse(localStorage.getItem("fpc_id")));
    // newFormData.append('user_id', JSON.parse(localStorage.getItem('userId')))

    console.log("uploaded files", uploadedFiles);

    for (let pair of formData.entries()) {
      console.log(` keys and values of form data${pair[0]}, ${pair[1]}`);
    }

    console.log(
      typeof formData,
      fileTypes,
      formData,
      formData.get("Copy of Saharapada_FPC_Members.xlsx"),
      newFormData.getAll("excel")
    );
    let fileUploaded = document.getElementById("uploade-file-input").files;
    console.log("file value", fileUploaded);

    let url = UrlConstants.base_url + UrlConstants.upload_file;

    let payload = {
      excel: formData,
      // org_id: JSON.parse(localStorage.getItem("org_id")),
      // user_id: JSON.parse(localStorage.getItem("userId")),
    };

    console.log("payload", newFormData, payload);

    setLoader(true);
    HTTPService("POST", url, newFormData, true, true, false, onUploadProgress)
      .then((res) => {
        setLoader(false);
        console.log("res", res);
        setSnackbarMessage(res.data.message);
        setSnackbarSeverity("success");
        setSnackbarState(true);
        handleCloseToast();
        setClearFiles(clearFiles+1)
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarMessage("Failed to upload data");
        setSnackbarSeverity("error");
        setSnackbarState(true);
        handleCloseToast();
        console.log("err", err);
      });
  };

  const handleProductCatalogUploadeFiles = (e) => {
    var file = document.querySelector("#uploade-product-catalog-file-input").files;

    console.log("file on change", file);
    setUploadedProductCatalog(file);
  };

  const handleProductCatalogSubmit = async (e) => {
    e.preventDefault();

    const formData = createFormData();
    let newFormData = new FormData();

    newFormData.append("excel", uploadedProductCatalog);
    // newFormData.append("org_id", JSON.parse(localStorage.getItem("org_id")));
    // newFormData.append("user_id", JSON.parse(localStorage.getItem("userId")));
    // newFormData.append("fpc_id", JSON.parse(localStorage.getItem("fpc_id")));
    // newFormData.append('user_id', JSON.parse(localStorage.getItem('userId')))

    console.log("uploaded files", uploadedProductCatalog);

    // for (const pair of formData.entries()) {
    //   console.log(` keys and values of form data${pair[0]}, ${pair[1]}`);
    // }

    console.log(
      typeof formData,
      fileTypes,
      formData,
      formData.get("Copy of Saharapada_FPC_Members.xlsx"),
      newFormData.getAll("excel")
    );
    let fileUploaded = document.getElementById(
      "uploade-product-catalog-file-input"
    ).files;
    console.log("file value", fileUploaded[0]);

    let url = UrlConstants.base_url + UrlConstants.upload_product_catlog;

    console.log("payload", newFormData);

    setLoader(true);
    HTTPService("POST", url, newFormData, true, true, false, onUploadProgress)
      .then((res) => {
        setLoader(false);

        setSnackbarMessage(res.data.message);
        setSnackbarSeverity("success");
        setSnackbarState(true);
        handleCloseToast();
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarMessage("Failed to upload data");
        setSnackbarSeverity("error");
        setSnackbarState(true);
        handleCloseToast();

        console.log("err", err);
      });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const formData = createFormData();
  //   console.log( typeof formData, fileTypes)

  //   let url = UrlConstants.base_url + UrlConstants.upload_file_end_point

  //       HTTPService("POST", url, formData, true, true, true, onUploadProgress)
  //       .then((res)=>{
  //         console.log('res',res)
  //       })
  //       .catch((err)=>{
  //         console.log('err',err)
  //       })

  // };

  // useEffect(()=>{
  //   let userRole = getRoleLocal();
  //   console.log('userRole',userRole)

  //   if(userRole==='fpc_admin' || userRole==='fpc_chairman' || userRole==='fpc_user'){
  //     navigate('/uploadfile')
  //   }
  //   else if(userRole==='super_user' || userRole==='admin_user' || userRole==='ri_user' || userRole==='ri_admin' || userRole==='flw'){
  //     navigate('/login')
  //   }
  //   else{
  //     navigate('/login')
  //   }
  // },[])
  // console.log("file",files, )

  // flushLocalstorage

  const handleCloseToast = () => {
    setTimeout(() => {
      setSnackbarState(false);
    }, 5000);
  };

  useEffect(() => {
    setUserName(JSON.parse(localStorage.getItem("first_name")));
    // setOrgName(JSON.parse(localStorage.getItem("org_name")));

    if (
      userRole === "fpc_admin" ||
      userRole === "fpc_chairman" ||
      userRole === "fpc_user" ||
      userRole == "1"
    ) {
      navigate("/uploadfile");
    } else if (
      userRole === "super_user" ||
      userRole === "admin_user" ||
      userRole === "ri_user" ||
      userRole === "ri_admin" ||
      userRole === "flw"
    ) {
      navigate("/login");
    } else {
      navigate("/login");
    }
  }, [userRole]);

  return (
    <>
      {loader ? <Loader /> : null}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackbarState}
        sx={{ width: "80%" }}
        autoHideDuration={1000}
      >
        <Alert
          // autoHideDuration={1000}
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "200%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar className="upload-file-navbar" position="static">
          <Toolbar>
            <Typography
              onClick={() => {
                navigate("/");
              }}
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {/* <img className="d2fo-logo-in-navbar" src={d2foLogo} alt="D2FO LOGO" /> */}
            </Typography>
            <Button
              onClick={() => {
                flushLocalstorage();
                setUserRole("");
              }}
              color="inherit"
            >
              Log out
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <div className="user-name-container">
        <h1>{`Hi, ${userName}`}</h1>
      </div>
      <UploadFile
      clearFiles={clearFiles}
        handleSubmit={handleSubmit}
        handleUploadeFiles={handleUploadeFiles}
        title={"Upload Amptech Farmer Data"}
      />
      {/* <UploadFile
        handleSubmit={handleProductCatalogSubmit}
        handleUploadeFiles={handleProductCatalogUploadeFiles}
        title={"Upload Product Catalog "}
      /> */}
    </>
    // <div className='upload-page-container'>

    //     <div className='upload-file-title'>

    //   <h1>Upload Farmer Registerery</h1>
    //     </div>
    //   <div
    // //   css={CSS}
    // className='upload-file-container'
    //   >

    //   {/* <p>Please use the form to your right to upload any file(s) of your choosing.</p> */}

    //   <div className="form-container">
    //     {/* Display the files to be uploaded */}

    //     {/* Provide a drop zone and an alternative button inside it to upload files. */}
    //     <div
    //     //   css={DropzoneCSS}
    //       onDragEnter={handleDragDropEvent}
    //       onDragOver={handleDragDropEvent}
    //       onDrop={(e) => {
    //         handleDragDropEvent(e);
    //         setFiles(e, 'a');
    //       }}
    //       onClick={() => inputRef.current.click()}
    //       className="drag-and-drop-div"
    //     >
    //   <UploadLogoImg/>
    //      <div className='drag-drop-placeholder-text'>
    //       <h3>Drag and drop files here</h3>
    //       <p>Drop files here or click to browse thorough your system</p>
    //     </div>
    //       {/* <button onClick={() => inputRef.current.click()}>Or select files to upload</button> */}

    //       {/* Hide the crappy looking default HTML input */}
    //       <input
    //         ref={inputRef}
    //         type="file"
    //         multiple
    //         style={{ display: 'none' }}
    //         onChange={(e) => {
    //           setFiles(e, 'a');
    //           inputRef.current.value = null;
    //         }}
    //         accept="application/vnd.ms-excel"
    //       />
    //     </div>
    //   </div>

    //   <div
    //     //  css={ListCSS}
    //     className='all-uploaded-files'
    //      >
    //         {files.map((file) => {
    //           let fileName = file.name.split(".")
    //           let fileType = fileName[fileName.length-1]
    //           if(fileType === 'xlsx' || fileType === 'xls'){

    //           }else{
    //             removeFile(file.name)
    //             // window.alert(fileType + ' file is not allowed')
    //           }
    //           return (
    //             <div className='uploaded-file'>

    //                 <ul>

    //           <li key={file.name}>
    //             <div>
    //             <span className='file-name-title'>{file.name}</span>
    //             <p className='file-size-title'>{(file.size/1000).toFixed(1)} KB</p>
    //             </div>

    //             <span onClick={() => removeFile(file.name)}>
    //               <i className="fa fa-times" />
    //              <CloseIcon/>
    //             </span>
    //           </li>
    //       </ul>
    //             </div>
    //         )})

    //       }

    //       {/* {files.length > 0 && (
    //         <ul>
    //           <li>File types found: {fileTypes.join(', ')}</li>
    //           <li>Total Size: {totalSize}</li>
    //           <li>Total Bytes: {totalSizeInBytes}</li>
    //           <li className="clear-all">
    //             <button onClick={() => clearAllFiles()}>Clear All</button>
    //           </li>
    //         </ul>
    //       )} */}
    //     </div>
    //     <div className="drag-and-drop-submit-btn-div">
    //     <button onClick={handleSubmit}>Upload File</button>
    //   </div>
    // </div>

    // </div>
  );
};

export default UploadFilePage;

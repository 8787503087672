const UrlConstants = {
    // base_url: "https://869e-106-51-85-143.in.ngrok.io/",
    // base_url: "https://d2fo.digitalgreen.org/be/",
    base_url: "http://65.1.104.245:8010/",
    upload_file_end_point: "farmer_registry/bulk_upload/",
    upload_product_catlog: "indent_collection/bulk_upload_product_catalog/",
    get_login_otp_end_point: "user/generate_otp/",
    verify_otp_end_point: "user/verify_otp/",
    login: "user/login/",
    upload_file: "main/upload/",
    
   
    // site_url:"",
}
export default UrlConstants